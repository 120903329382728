import React from "react"
import fetch from "cross-fetch"
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                posts: relayStylePagination(["where"]),
            },
        },
    },
})
const link = new HttpLink({
    /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
    uri: "https://bibletolife.wpengine.com/graphql",
    /* Use fetch from cross-fetch to provide replacement for server environment */
    fetch
})
const client = new ApolloClient({
    link,
    cache,
})
export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>{element}</ApolloProvider>
)