exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-authors-js": () => import("./../../../src/pages/resources/authors.js" /* webpackChunkName: "component---src-pages-resources-authors-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-perspectives-js": () => import("./../../../src/pages/resources/perspectives.js" /* webpackChunkName: "component---src-pages-resources-perspectives-js" */),
  "component---src-pages-resources-scripture-js": () => import("./../../../src/pages/resources/scripture.js" /* webpackChunkName: "component---src-pages-resources-scripture-js" */),
  "component---src-pages-resources-topics-js": () => import("./../../../src/pages/resources/topics.js" /* webpackChunkName: "component---src-pages-resources-topics-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-perspective-by-type-js": () => import("./../../../src/templates/PerspectiveByType.js" /* webpackChunkName: "component---src-templates-perspective-by-type-js" */),
  "component---src-templates-perspective-index-by-type-js": () => import("./../../../src/templates/PerspectiveIndexByType.js" /* webpackChunkName: "component---src-templates-perspective-index-by-type-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/Resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-scripture-by-type-js": () => import("./../../../src/templates/ScriptureByType.js" /* webpackChunkName: "component---src-templates-scripture-by-type-js" */),
  "component---src-templates-scripture-index-by-type-js": () => import("./../../../src/templates/ScriptureIndexByType.js" /* webpackChunkName: "component---src-templates-scripture-index-by-type-js" */),
  "component---src-templates-term-js": () => import("./../../../src/templates/Term.js" /* webpackChunkName: "component---src-templates-term-js" */),
  "component---src-templates-topic-by-type-js": () => import("./../../../src/templates/TopicByType.js" /* webpackChunkName: "component---src-templates-topic-by-type-js" */),
  "component---src-templates-topic-index-by-type-js": () => import("./../../../src/templates/TopicIndexByType.js" /* webpackChunkName: "component---src-templates-topic-index-by-type-js" */)
}

